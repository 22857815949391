import React from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip';
import { TimelockItem } from 'API';
import useTaskInfo from '../../hooks/useTaskInfo'

type BadgeProps = {
  timelock: TimelockItem;
  className?: string;
  children: React.ReactNode;
}

const StyledBadge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 5px 7px;
  margin: 6px 0px;
  border-radius: 9999px;
  font-size: 10px;
  font-weight: 500;
  color: #EB2F71;
  background: rgba(168, 154, 255, 0.15);
`

const TaskTime = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-weight: bold;
  color: #FCCE14;
`

const TooltipContent = styled.div`
  padding: 8px;
  font-size: 0.875rem;
  line-height: 1.5;

  h4 {
    margin: 0 0 8px;
    font-size: 1rem;
  }

  ul {
    padding-left: 1.2em;
    margin: 0;
    list-style-type: disc;
  }
`;

export function CalendarItem({ timelock, className, children }: BadgeProps) {
  const { data } = useTaskInfo(timelock.task, timelock.chainId)

  const tooltipContent = (
   <TooltipContent>
     <h4>Timelock</h4>
     <ul>
       <li>Task Name: {data ? data.name : 'Loading...'}</li>
       <li>Task address: {timelock.task}</li>
       <li>Chain ID: {timelock.chainId}</li>
       <li>Client: {timelock.namespace}</li>
       <li>Start date: {timelock.start}</li>
       <li>End date: {timelock.end}</li>
     </ul>
   </TooltipContent>
 );
  return (
    <>
      <TaskTime>
        {new Date(timelock.start).toLocaleTimeString()}
      </TaskTime>
      <Tooltip title={tooltipContent} arrow>
        <div>
          <div>{data && data.name}</div>
          <StyledBadge  className={className}>
            {children}
          </StyledBadge>
        </div>
      </Tooltip>
    </>
  )
}

import { useContext } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'

interface Task {
  id: string;
  name: string;
}

interface TaskInfoResponse {
  task: Task;
  name?: string
}


const useTaskInfo = (id: string, chainId: number): UseQueryResult<TaskInfoResponse | null> => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery<TaskInfoResponse | null>({
    queryKey: ['useTaskInfo', id + chainId],
    queryFn: () => fetchTaskInfo(id, chainId, subgraphs),
  })
}

const fetchTaskInfo = async (id: string, chainId: number, subgraphs: Record<string, string>): Promise<TaskInfoResponse | null> => {
  try {
    const data = await request<{ task: any }>(
      subgraphs[chainId],
      gql`
        query TaskInfo($id: String!) {
          task(id: $id) {
            name
          }
        }
      `,
      { id: id.toLowerCase() }
    )

    return data.task
  } catch (error) {
    console.error(`Error fetching task info:`, error)
    return null
  }
}

export default useTaskInfo

import { useQuery } from '@tanstack/react-query';
import { QueueExecutionsResponse, EnvironmentResponse, TimelockResponse, TimelockItem } from 'API'; // Asegúrate de que la interfaz EnvironmentResponse esté correctamente definida
import axios from 'axios';
import { refresh } from '../utils/web3-utils';

interface Environment {
  namespace?: string;
  chainIds?: number[];
  mimicId?: string;
}

interface Timelock {
  mimicId: string;
  namespace?: string;
  timelocks: any[]; // Define mejor el tipo según la estructura real de los timelocks
}

interface SmartVaultResponse {
  _id: string; // Asegúrate de que esta propiedad exista en el objeto
  // Agrega otras propiedades según sea necesario
}

const URL = process.env.REACT_APP_SERVER_BASE_URL;
const AUTH_TOKEN = localStorage.getItem('token');

async function fetchData(environments: Environment[]): Promise<TimelockResponse[]> {
  try {
    if (environments.length === 0) {
      return []; // Retorna un array vacío si no hay entornos
    }

    // Maneja el caso donde AUTH_TOKEN podría ser null
    if (!AUTH_TOKEN) {
      throw new Error('AUTH_TOKEN is missing');
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'x-auth-token': AUTH_TOKEN, // Ya no es necesario `${AUTH_TOKEN}` porque se ha verificado que tiene valor
    };

    // Inicializa el objeto donde se acumularán los timelocks
    const mergedTimelocks: { [key: string]: TimelockResponse } = {};

    for (const item of environments) {
      if (item.mimicId) {
        const response = await axios.get<EnvironmentResponse>(
          `${URL}/relayer-executor/environments/${item.mimicId}`,
          { headers }
        );

        // Asegúrate de que `response.data.smartVaults` es un array y está definido
        if (Array.isArray(response.data.smartVaults)) {
          const timelocksPromises = response.data.smartVaults.map((smartVault) =>
            axios.get(`${URL}/relayer-executor/smart-vaults/${smartVault?._id}/timelocks`, {
              headers: headers,
            })
            .then((timelockResponse) => ({
              data: timelockResponse.data,
              smartVaultId: smartVault._id,
              smartVaultChain:  smartVault.chainId,
            }))
          );

          const timelocksResponses = await Promise.all(timelocksPromises);

          timelocksResponses.forEach((timelockResponse) => {
            if (timelockResponse.data.length > 0) {
              const key = `${item.mimicId}-${item.namespace}`;

              if (!mergedTimelocks[key]) {
                mergedTimelocks[key] = {
                  mimicId: item.mimicId,
                  namespace: item.namespace,
                  timelocks: [],
                };
              }

              const timelocksWithChainId = timelockResponse.data.map((timelock: any) => ({
                ...timelock,
                chainId: timelockResponse.smartVaultChain,
              }));

              mergedTimelocks[key]?.timelocks?.push(...timelocksWithChainId);
            }
          });
        }
      }
    }

    return Object.values(mergedTimelocks);
  } catch (error) {
    if ((error as any).response?.status === 401) {
      try {
        await refresh();
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.');
      }
    }
    console.error('Error fetching queue executions:', error);
    throw error;
  }
}

function useGeneralTimelocks(environments: Environment[]) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['timelocks', environments],
    queryFn: () => fetchData(environments),
  });

  return { data, isLoading, error, refetch };
}

export { useGeneralTimelocks };

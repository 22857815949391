import React, { useRef, forwardRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import 'react-datepicker/dist/react-datepicker.css'

interface CalendarProps {
  label: string
  selectedDate: Date | null
  onChange: (date: Date | null) => void
  withHour?: boolean
  startMonth?: Date

}

const Calendar: React.FC<CalendarProps> = ({ label, selectedDate, onChange, withHour = false, startMonth = undefined }) => {
  const customDateButtonRef = useRef<HTMLButtonElement>(null)
  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        showTimeSelect={withHour}
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat={withHour ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        customInput={<CustomDateButton ref={customDateButtonRef} label={label} />}
        openToDate={startMonth}
      />
    </div>
  )
}

interface CustomDateButtonProps {
  value?: string
  onClick?: () => void
  label: string
}

const CustomDateButton = forwardRef<HTMLButtonElement, CustomDateButtonProps>(({ value, onClick, label }, ref) => {
  return (
    <Button ref={ref} onClick={onClick}>
      <>
        {value ? moment(value).format('YYYY-MM-DD HH:mm') : label} <CalendarIcon />
      </>
    </Button>
  )
})

const Button = styled.button`
  border-radius: 10px;
  background: rgba(168, 154, 255, 0.1);
  border: solid 0px;
  color: #fff;
  font-family: 'DMSansBold';
  padding: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 33px;
  margin-top: 15px;
`

export default Calendar

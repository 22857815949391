import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment';
import { ButtonViolet } from '../utils/styles'
import { CalendarItem } from '../components/calendar/CalendarItem'
import { useEnvironments } from '../hooks/useEnvironments'
import { useGeneralTimelocks } from '../hooks/useGeneralTimelocks'
import { TimelockResponse, TimelockItem } from 'API';


const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

const getDaysInMonth = (date: Date): Date[] => {
  const days = []
  const firstDayOfMonth = getFirstDayOfMonth(date)
  const lastDayOfMonth = getLastDayOfMonth(date)

  for (let i = firstDayOfMonth.getDate(); i <= lastDayOfMonth.getDate(); i++) {
    days.push(new Date(date.getFullYear(), date.getMonth(), i))
  }

  const firstDayIndex = firstDayOfMonth.getDay()
  if (firstDayIndex !== 0) {
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      days.unshift(new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), -i))
    }
  }

  const lastDayIndex = lastDayOfMonth.getDay()
  if (lastDayIndex !== 6) {
    for (let i = 1; i <= 6 - lastDayIndex; i++) {
      days.push(new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + i))
    }
  }

  return days
}

export default function TimelockCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [dateRange, setDateRange] = useState<Date[]>([])
  const { data: environmentsData } = useEnvironments();
  const { data } = useGeneralTimelocks(environmentsData ?? []);

  useEffect(() => {
    const days = getDaysInMonth(currentDate)
    setDateRange(days)
  }, [currentDate])

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0]
  }

  const isToday = (date: Date): boolean => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear()
  }

  const moveMonth = (months: number) => {
    const newDate = new Date(currentDate)
    newDate.setMonth(currentDate.getMonth() + months)
    setCurrentDate(newDate)
  }

  const filterTimelocksForDate = (date: string, environments: TimelockResponse[]) => {
    const targetDate = new Date(date);
    const allTimelocks = environments.flatMap(environment =>
      (environment.timelocks || []).map(timelock => ({
        ...timelock,
        namespace: environment.namespace
      }))
    );

    const response = allTimelocks.filter(timelock => {
      return moment(targetDate).format('YYYY-MM-DD') === moment(timelock.start).format('YYYY-MM-DD');
    });

  return response
};

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return (
    <CalendarContainer>
      <Header>
        <ButtonViolet onClick={() => moveMonth(-1)}>
          Previous
        </ButtonViolet>
        <h2>
          {currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
        </h2>
        <ButtonViolet onClick={() => moveMonth(1)}>
          Next
        </ButtonViolet>
      </Header>
      <WeekHeader>
        {daysOfWeek.map((day, index) => (
          <div key={index}>{day}</div>
        ))}
      </WeekHeader>
      <Grid>
        {dateRange.map((date, index) => (
          <DateCell key={index}>
            <DayNumber istoday={isToday(date).toString()}>
              {date.getDate()}
            </DayNumber>
            {data &&
            <ScrollArea>
              {filterTimelocksForDate(date.toString(), data).length > 0 && (
              <>
                 {filterTimelocksForDate(date.toString(), data)
                   .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
                   .map((timelock, index) => (
                   <TaskContainer key={index}>
                     <CalendarItem timelock={timelock}>{timelock.namespace}</CalendarItem>
                   </TaskContainer>
                 ))}
              </>)
            }
            </ScrollArea>
          }
          </DateCell>
        ))}
      </Grid>
    </CalendarContainer>
  )
}

const CalendarContainer = styled.div`
  padding: 16px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const WeekHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  margin-bottom: 8px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
`

const DateCell = styled.div`
  padding: 8px;
  border: 1px solid #323244;
  border-radius: 8px;
  background-color: #323244;
  color: #fff;
  height: 200px;
  overflow: hidden;
  position: relative;
`
const ScrollArea = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 12rem;
  padding: 4px;
  top: 30px;
`

const DayNumber = styled.div<{ istoday: string }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ istoday }) => (istoday === 'true' ? '#f91616' : 'transparent')}; /* Círculo rojo si es hoy */
  color: #ffffff; /* Texto blanco si es hoy */
  font-weight: bold;
  position: absolute;
  top: 8px;
  left: 8px;
`

const TaskContainer = styled.div`
  margin-bottom: 8px;
  font-size: 0.875rem;
`

import { CHAIN_INFO } from '../constants/chainInfo'
import axios from 'axios'
import JSBI from 'jsbi'

const URL = process.env.REACT_APP_SERVER_BASE_URL
export const NO_BREAK_SPACE = '\u00a0'

export function shortenAddress(address: string, charsLength = 4): string {
  const prefixLength = 2 // "0x"
  if (!address) {
    return ''
  }
  if (address.length < charsLength * 2 + prefixLength) {
    return address
  }
  return address.slice(0, charsLength + prefixLength) + '…' + address.slice(-charsLength)
}

export function shortenHash(hash: string, charsLength = 4): string {
  const prefixLength = 0 // No prefix in this case
  if (!hash) {
    return ''
  }

  if (hash?.length < charsLength * 2 + prefixLength) {
    return hash
  }
  return hash.slice(0, charsLength + prefixLength) + '…' + hash.slice(-charsLength)
}

export function convertWeiToGwei(wei: number | null | undefined): number {
  if (!wei) return 0
  const gwei = wei / 1000000000
  return gwei
}

export function convertGweiToWei(gwei: number | null | undefined): number {
  if (!gwei) return 0
  const wei = gwei * 1000000000
  return wei
}

enum EtherscanLinkType {
  Transaction = 'transaction',
  Token = 'token',
  Address = 'address',
}

export function getEtherscanLink(chainId: number, data: string, type: `${EtherscanLinkType}`): string {
  const prefix = `${CHAIN_INFO[chainId]?.explorer || CHAIN_INFO[1]?.explorer}`
  switch (type) {
    case EtherscanLinkType.Transaction: {
      return `${prefix}tx/${data}`
    }
    case EtherscanLinkType.Token: {
      return `${prefix}token/${data}`
    }
    case EtherscanLinkType.Address:
    default: {
      return `${prefix}address/${data}`
    }
  }
}

export function isAddress(address: string): boolean {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false
  }

  return true
}

export function formatNumber(number: number | string | null | undefined): string {
  if (number === null || number === undefined || number === 0) return '0'

  const numAsString = String(number)

  const [integer, decimals] = numAsString.split('.')

  return integer.split('').reverse().reduce(
    (result, digit, index) => {
      return digit + (index > 0 && index % 3 === 0 ? ',' : '') + result
    },
    decimals ? `.${decimals}` : ''
  )
}

export function divideRoundBigInt(dividend: JSBI | number | string, divisor: JSBI | number | string): string {
  const bigIntDividend = JSBI.BigInt(String(dividend))
  const bigIntDivisor = JSBI.BigInt(String(divisor))
  return JSBI.divide(JSBI.add(bigIntDividend, JSBI.divide(bigIntDivisor, JSBI.BigInt(2))), bigIntDivisor).toString()
}

export function compareAddresses(address1: string, address2: string): boolean {
  if (!address1 || !address2) {
    console.error('compareAddresses: Both addresses must be provided')
    return false
  }

  const normalizedAddress1 = address1.toLowerCase()
  const normalizedAddress2 = address2.toLowerCase()

  return normalizedAddress1 === normalizedAddress2
}

export function percent(num: number): string {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }).format(num)
}

export const refresh = async (): Promise<void> => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    const response = await axios.post(
      `${URL}/public/users/renew-token`,
      {
        refreshToken,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    localStorage.setItem('token', response.data.accessToken)
  } catch (error) {
    console.error('Unable to refresh token')
    logout()
  }
}

export function logout(): void {
  localStorage.removeItem('token')
  window.location.reload()
}

export function filterByNamespace<T extends { namespace?: string }>(objects: T[], searchTerm: string): T[] {
  const lowerCaseSearchTerm = searchTerm?.toLowerCase()

  return objects?.filter((obj) => obj?.namespace?.toLowerCase()?.includes(lowerCaseSearchTerm))
}

import React from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import ApiSettings from '../components/settings/config/ApiSettings';
import Web3Settings from '../components/settings/config/Web3Settings';
import TokenRegistry from '../components/settings/config/TokenRegistry';
import Notifications from '../components/settings/config/Notifications';
import RelayerExecutorForm from '../components/settings/config/RelayerExecutorForm';
import Users from '../components/settings/UserList';
import Crons from '../components/settings/Crons';
import Subgraphs from '../components/settings/Subgraphs';
import Logs from '../components/settings/logs/Logs';
import LogsGroup from '../components/settings/logs/LogsGroup';
import LogsItem from '../components/settings/logs/LogsItem';
import Registry from '../components/settings/Registry';
import Networks from '../components/settings/network/NetworksPage';
import Rpcs from '../components/settings/network/Rpcs';
import Pods from '../components/settings/Pods';
import { PriceOracleSettings } from '../components/settings/config/PriceOracle';
import { Tab, H2, H1, Section } from '../utils/styles';

export default function Settings() {
  return (
    <Section>
      <H1>General</H1>
      <Tab>
        <TabLink to="config">Config</TabLink>
        <TabLink to="registry">Registry</TabLink>
        <TabLink to="users">Users</TabLink>
        <TabLink to="networks">Networks</TabLink>
        <TabLink to="rpcs">RPCs</TabLink>
        <TabLink to="subgraphs">Subgraphs</TabLink>
        <TabLink to="crons">Crons</TabLink>
        <TabLink to="pods">Pods</TabLink>
        <TabLink to="logs">Logs</TabLink>
      </Tab>
      <div>
        <Routes>
          <Route path="config/*" element={<ConfigTab />} />
          <Route path="registry/*" element={<Registry />} />
          <Route path="users/*" element={<Users />} />
          <Route path="networks/*" element={<Networks />} />
          <Route path="rpcs/*" element={<Rpcs />} />
          <Route path="subgraphs/*" element={<Subgraphs />} />
          <Route path="crons/*" element={<Crons />} />
          <Route path="pods/*" element={<Pods />} />
          <Route path="logs/groups/*" element={<LogsGroup />} />
          <Route path="logs/:id/groups/*" element={<LogsItem />} />
          <Route path="logs/*" element={<Logs />} />
        </Routes>
      </div>
    </Section>
  );
}

function ConfigTab() {
  return (
    <div>
      <H2>Relayer Executor</H2>
      <RelayerExecutorForm />
      <br />
      <br />
      <H2>Web3</H2>
      <Web3Settings />
      <br />
      <br />
      <H2>Price Oracle</H2>
      <PriceOracleSettings />
      <br />
      <br />
      <H2>Token Registry</H2>
      <TokenRegistry />
      <br />
      <br />
      <H2>Notifications</H2>
      <Notifications />
      <br />
      <br />
      <H2>API</H2>
      <ApiSettings />
    </div>
  );
}

const TabLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to);
  return (
    <StyledLink to={`/dashboard/settings/${to}`} className={isActive ? 'active' : ''}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  background: none;
  color: white;
  font-size: 16px;
  line-height: 32px;
  margin-top: 0px !important;
  border-radius: 0px !important;

  &.active {
    border-bottom: 5px solid #5ce6d5;
  }
  &:hover {
    color: white;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

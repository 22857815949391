import React from 'react'
import styled from 'styled-components'

interface SwitchProps {
  ison: boolean;
  onToggle: () => void;
  onColor?: string;
  offColor?: string;
}

const StyledSwitch = styled.div<{ ison: boolean; onColor: string; offColor: string }>`
  width: 32px;
  height: 16px;
  background-color: ${({ ison, onColor, offColor }) => (ison ? onColor : offColor)};
  border-radius: 15px;
  position: relative;
  cursor: pointer;
`

const SwitchHandle = styled.div<{ ison: boolean }>`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s ease;
  transform: ${({ ison }) => (ison ? 'translateX(16px)' : 'translateX(0)')};
`

const Switch: React.FC<SwitchProps> = ({
  ison,
  onToggle,
  onColor = '#5CE6D5',
  offColor = '#F94872',
}) => {
  return (
    <StyledSwitch ison={ison} onColor={onColor} offColor={offColor} onClick={onToggle}>
      <SwitchHandle ison={ison} />
    </StyledSwitch>
  )
}

export default Switch

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const dividerImg = require('../../assets/breadcrumb-divider.svg').default;


interface Path {
  name: string;
  url: string;
}

interface BreadcrumbsProps {
  paths: Path[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ paths }) => {
  return (
    <BreadcrumbsSection>
      {paths.map((path, index) => (
        <span key={index}>
          <p>
            {index < paths.length - 1 ? (
              <Link to={path.url}>{path.name}</Link>
            ) : (
              path.name
            )}
          </p>
          {index < paths.length - 1 && <Divider src={dividerImg} alt=">" />}
        </span>
      ))}
    </BreadcrumbsSection>
  );
};

const BreadcrumbsSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
  span, a {
    display: flex;
    align-items: center;
  }
  a:hover {
    color: #33c2b0!important;
  }
  p {
    color: #a7acc1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'DMSans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
  }
`;

const Divider = styled.img`
  padding: 0 4px;
`;

export default Breadcrumbs;

import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { Duration } from 'moment'
import { CronStatusResponse } from '../types/API'

const URL = process.env.REACT_APP_SERVER_BASE_URL

const useCrons = (): UseQueryResult<CronStatusResponse[]> => {
  return useQuery({
    queryKey: ['crons'],
    queryFn: fetchData,
  })
}

const fetchData = async (): Promise<CronStatusResponse[]> => {
  const token = localStorage.getItem('token')
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': `${token}`,
  }

  try {
    const response = await axios.get<CronStatusResponse[]>(`${URL}/admin/crons`, {
      headers: headers,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchData()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('There was an error loading the data:', error)
    throw error
  }
}

export const formatDuration = (duration: Duration): string => {
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  let formattedDuration = ''
  if (hours > 0) {
    formattedDuration += `${hours} h${hours > 1 ? 's' : ''} `
  }
  if (minutes > 0) {
    formattedDuration += `${minutes} min`
  }
  if (seconds > 0) {
    formattedDuration += `${seconds} sec`
  }

  return formattedDuration.trim()
}

export default useCrons

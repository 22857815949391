/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import axios from 'axios'
import { logout } from '../../utils/web3-utils'
import { ButtonViolet, Loader } from '../../utils/styles'
import arrow from '../../assets/arrow-down.png'
import toast, { Toaster } from 'react-hot-toast'
import useLoggedUser from '../../hooks/useLoggedUser'
import NotificationCard from './notifications/NotificationCard'
import useAdminNotifications from '../../hooks/useAdminNotifications'
import { ButtonColor, Detail, NotificationsContainer, PasswordForm } from './styles'

interface Props {
  open: boolean
  onClose: () => void
}

const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
const PASSWORD_ERROR_MSG =
  'Password must be min 8 chars, with at least a symbol, upper and lower case letters, and a number'

const NOTIFICATIONS_LIMIT = 20

const UserPanel = ({ open, onClose }: Props) => {
  const [form, setForm] = useState(false)
  const [password, setPassword] = useState<string | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null)
  const { data: me } = useLoggedUser()
  const {
    notifications, 
    isLoading: notificationsLoading, 
    isFetching, 
    dismissedNotifications, 
    dismissNotification, 
    showNotificationsAgain, 
    fetchNextPage 
  } = useAdminNotifications({ limit: NOTIFICATIONS_LIMIT })
  
  const unviewedNotifications = notifications?.filter((notif) => !dismissedNotifications.has(notif._id))

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      toast('Both password must be the same')
    }
    try {
      const token = localStorage.getItem('token')
      await axios.put(
        `${URL}/admin/users/${me?._id}`,
        {
          ...me,
          password: password,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )

      toast('Your password has been successfully changed')
      setForm(false)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast(`Error: ${error.response.data.message}`)
      } else {
        toast('Error: An unexpected error occurred')
      }
    }
  }

  const handleClick = () => {
    setForm(!form)
  }

  const showLoadMoreButton = notifications.length % NOTIFICATIONS_LIMIT === 0

  return (
    <Detail isopen={open}>
      <Toaster position="top-right" />
      <div className="overlay" onClick={onClose} />
      <div className="wrap" onClick={(e: any) => e.target.nodeName.toLowerCase() === 'a' && onClose()}>
        <h3>
          {me?.email} <ButtonColor onClick={logout}>Logout</ButtonColor>
        </h3>

        <h4 onClick={handleClick}>
          Change Password <img className={form ? 'upside-down' : ''} src={arrow} />
        </h4>
        {form && (
          <PasswordForm className="small" onSubmit={handleFormSubmit}>
            <div className="input-group">
              <label>New Password</label>
              <input
                type="password"
                value={password ?? ''}
                onChange={(e) => setPassword(e.target.value)}
                pattern={String(PASSWORD_PATTERN)}
                required
                title={PASSWORD_ERROR_MSG}
              />
            </div>
            <div className="input-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword ?? ''}
                onChange={(e) => setConfirmPassword(e.target.value)}
                pattern={String(PASSWORD_PATTERN)}
                required
                title={PASSWORD_ERROR_MSG}
              />
            </div>
            <ButtonViolet type="submit">Change</ButtonViolet>
          </PasswordForm>
        )}
        <NotificationsContainer>
          {notificationsLoading && <Loader />}
          {unviewedNotifications && unviewedNotifications.length > 0 && unviewedNotifications.map((notification) => <NotificationCard key={notification._id} notification={notification} onDismiss={dismissNotification} />)}
          {unviewedNotifications && unviewedNotifications.length === 0 && <ButtonColor onClick={showNotificationsAgain}>Show dismissed notifications</ButtonColor>}
          {showLoadMoreButton && <ButtonColor disabled={isFetching} onClick={() => fetchNextPage()}>{isFetching ? 'Loading...' : 'Load more notifications'}</ButtonColor>}
        </NotificationsContainer>
      </div>
    </Detail>
  )
}

export default UserPanel

import axios from 'axios'
import { refresh } from '../utils/web3-utils'
import { UserResponse } from '../types/API'
import { useQuery } from '@tanstack/react-query'

const URL = process.env.REACT_APP_SERVER_BASE_URL

const fetchMe = async () => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get<UserResponse>(`${URL}/admin/users/me`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${token}`,
      },
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error?.response?.status === 401) {
        try {
          await refresh()
          await fetchMe()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Error al obtener la lista de usuarios:', error)
    } else {
      console.error('Error al obtener la lista de usuarios:', error)
    }
  }
}
function useLoggedUser() {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['me'],
    queryFn: () => fetchMe(),
  })

  return { data, isLoading, error, refetch }
}

export default useLoggedUser
import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import bg from '../../assets/bg.png'
import { ButtonViolet, Message, Group, H2 } from '../../utils/styles'
import { NewForm } from '../../utils/typescript-styles'
import Select from 'react-select'
import CustomInput from '../general/Form'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface FormProps {
  onSuccess?: () => void
}

const SignUpForm: React.FC<FormProps> = ({ onSuccess = () => {} }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [roles, setRoles] = useState<string[]>([])

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRoles = Array.from(e.target.selectedOptions, (option) => option.value)
    setRoles(selectedRoles)
  }

  const rolesList = [
    { value: 'viewer', label: 'viewer' },
    { value: 'maintainer', label: 'maintainer' },
    { value: 'manager', label: 'manager' },
    { value: 'root', label: 'root' },
  ]

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        `${URL}/admin/users`,
        {
          email,
          password,
          roles,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )

      setMessage('The user has been successfully created')
      onSuccess()
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setMessage(`Error: ${error.response.data.message}`)
      } else {
        setMessage('Error: An unexpected error occurred')
      }
    }
  }

  const handleSelectRoles = (e: any) => {
    const roles = e?.map((item: any) => item.value)
    setRoles(roles)
  }

  const showOptions = (list: string[] | undefined): { value: string; label: string }[] => {
    if (!list) return []
    return list.map((o: string) => ({ value: o, label: o }))
  }

  return (
    <NewForm onSubmit={handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <H2>New user</H2>
          <Group>
            <div className="input-group">
              <CustomInput
                type="text"
                label="Email"
                name="Email"
                modal={true}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <CustomInput
                type="text"
                label="Password"
                name="Password"
                modal={true}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                title="Password must be min 8 chars, with at least a symbol, upper and lower case letters, and a number"
                required
              />
            </div>
            <div className="input-group">
              <label>Roles</label>
              <StyledSelect
                classNamePrefix="Select"
                isMulti
                value={showOptions(roles)}
                onChange={handleSelectRoles}
                options={rolesList}
              />
            </div>
          </Group>
          <ButtonViolet type="submit">New user</ButtonViolet>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </NewForm>
  )
}

const StyledSelect = styled(Select)`
  .Select__control {
    min-width: 300px;
    border-radius: 10px;
    color: white;
    background: #323244;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .Select__menu {
    color: white;
    background: #323244;
    &:hover {
      background-color: #4a4a63;
    }
  }
  .Select__value-container {
    padding: 0 8px !important;
  }
  .Select__input-container {
    margin: 0 2px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .Select__option {
    padding: 10px;
    cursor: pointer;
    background: #323244;

    &:hover {
      background-color: #4a4a63;
    }

    &.Select__option--is-focused {
      background-color: #4a4a63;
    }

    &.Select__option--is-selected {
      background-color: #616182;
    }
  }
`

export default SignUpForm
